import React, { useState } from 'react';
import { Box, Button, Modal, Typography, TextField, Avatar, CircularProgress } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

interface UploadAvatarModalProps {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File) => Promise<void>;
  currentAvatarUrl: string;
}

const PopupChangeAvatar: React.FC<UploadAvatarModalProps> = ({ open, onClose, onUpload, currentAvatarUrl }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsUploading(true);
      try {
        await onUpload(selectedFile);
        setSelectedFile(null);
        onClose();
      } catch (error) {
        console.error('Upload failed:', error);
      } finally {
        setIsUploading(false);
      }
    }
  };
  if (!open) return null;

  return (
    <Modal open={open} onClose={onClose} aria-labelledby='modal-title'>
      <Box sx={style}>
        <Typography id='modal-title' variant='h6' component='h2'>
          Thay đổi Avatar
        </Typography>
        <Box mt={2} display='flex' flexDirection='column' alignItems='center'>
          <TextField type='file' onChange={handleFileChange} fullWidth />
          <Box mt={2} display='flex' justifyContent='space-between' gap={2}>
            <Button variant='outlined' onClick={onClose} disabled={isUploading}>
              Hủy
            </Button>
            <Button variant='contained' onClick={handleUpload} disabled={!selectedFile || isUploading}>
              {isUploading ? <CircularProgress size={24} /> : 'Tải lên'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PopupChangeAvatar;
