import { Logout, Menu } from '@mui/icons-material';
import { AppBar, Avatar, Button, Drawer, IconButton, Toolbar, Tooltip } from '@mui/material';
import { AppBreadcrumb, AppMenu } from 'containers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector, saveProfile, signOut } from 'reducers/profileSlice';
import { privateRoute } from 'routes';
import { PopupChangeAvatar } from 'views/User/popups';
import { authService } from 'services';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpenPopup = () => setIsPopupOpen(true);

  const { isLoggedIn, name, avatar, id } = useSelector(profileSelector);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleUploadAvatar = async (file: File) => {
    try {
      const userId = id ?? '';
      // Gọi service `avatarChanger` để gửi file lên server
      const response = await authService.avatarChanger(userId, file);
      console.log('API response:', response);
      // Cập nhật avatar mới trong Redux store
      dispatch(saveProfile({ avatar: response.avatarUrl }));
    } catch (error) {
      console.error('Error uploading avatar:', error);
    }
  };

  useEffect(() => {}, [avatar]);

  useEffect(() => {
    setOpenDrawer(false);
  }, [location.pathname]);

  return (
    <>
      <Drawer
        variant={'temporary'}
        anchor='left'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{ style: { padding: '8px 16px' } }}
      >
        <div className='flex h-[56px] items-center justify-center'>
          <Link to={privateRoute.home.path}>
            <span className='text-2xl font-bold text-red-500'>PCTH</span>
          </Link>
        </div>
        <AppMenu />
      </Drawer>

      <AppBar position='sticky' className='bg-white' elevation={1}>
        <Toolbar>
          <IconButton onClick={() => setOpenDrawer(true)} className='mr-2'>
            <Menu />
          </IconButton>
          <AppBreadcrumb />
          <div className='flex-1' />
          {isLoggedIn ? (
            <div className='flex flex-1 items-center justify-end'>
              <Button variant='outlined' color='warning' onClick={handleOpenPopup}>
                <Avatar className='mr-2 h-6 w-6 text-sm' src={avatar || undefined} alt={name}>
                  {!avatar && name?.charAt(0)} {/* Hiển thị chữ cái đầu tên nếu avatar chưa có */}
                </Avatar>
                {name}
              </Button>
              <Tooltip title='Đăng xuất'>
                <IconButton className='mx-3' onClick={() => dispatch(signOut({}))}>
                  <Logout />
                </IconButton>
              </Tooltip>
              <div>
                {
                  <PopupChangeAvatar
                    open={isPopupOpen}
                    onClose={() => setIsPopupOpen(false)} // Đóng popup
                    onUpload={handleUploadAvatar}
                    currentAvatarUrl={''}
                  />
                }
              </div>
            </div>
          ) : (
            <>
              <Button variant='outlined'>Login</Button>
              <Button variant='outlined'>Sign out</Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
